<template>
  <div v-if="result" class="varige-response">
    <Intro />

    <div class="compiled-steps">
      <Step1Form :edit="false" />
      <Step2Form :edit="false" />
      <Step3Form :edit="false" />
      <Step4Form :edit="false" />
      <Step5Form :edit="false" />
    </div>

    <div class="varige-response-page">
      <h2>Esito dell'autovalutazione <span  v-if="companyName">per {{ companyName }} ({{ referenceYear }})</span></h2>

      <div v-if="isUniversita" class="text-right">
        <button type="button" class="btn btn-secondary mb-3" @click.prevent="downloadBilancioDiGenere">Download Bilancio di Genere precompilato</button>
      </div>

      <div class="varige-response-legend">
        <h3>Legenda della valutazione</h3>
        <dl>
          <dt class="semaphore-green">Verde</dt>
          <dd>
            <img class="face" :src="require('@/assets/images/response/happy-face-p.png')" />
            <p>
              Adeguatezza dell'area tra il sufficientemente adeguato ed ottimale.
            </p>
            <p class="only-print">Evidenziato da una faccina sorridente.</p>
          </dd>

          <dt class="semaphore-yellow">Giallo</dt>
          <dd>
            <img class="face" :src="require('@/assets/images/response/neutral-face-p.png')" />
            <p>
              Adeguatezza dell'area tra inferiore alla sufficienza: è necessaria
              un'implementazione dell'attenzione al genere.
            </p>
            <p class="only-print">Evidenziato da una faccina perplessa.</p>
          </dd>

          <dt class="semaphore-red">Rosso</dt>
          <dd>
            <img class="face" :src="require('@/assets/images/response/sad-face-p.png')" />
            <p>
              Scarsa adeguatezza dell'area in ottica di genere.
            </p>
            <p class="only-print">Evidenziato da una faccina triste.</p>
          </dd>

          <dt>Grigio</dt>
          <dd>
            <img class="face" :src="require('@/assets/images/response/no-face-p.png')" />
            <p>I dati raccolti in quest'area non sono valutati ai fini dell'analisi di adeguatezza dell'ottica di genere (es. Anagrafica Aziendale e Distribuzione anagrafica). L'analisi di tali dati evidenzia eventuali squilibri nella distribuzione anagrafica in ottica di genere meritevoli di approfondimento e suggerisce l'adozione di alcune misure correttive.</p>
            <p class="only-print">Non è associata nessuna faccina.</p>
          </dd>
        </dl>
        <p>
          All'interno delle singole aree oltre ad un colore per indicare visivamente l'adeguatezza
          rispetto alla valutazione in ottica di genere vengono mostrati avvisi che consigliano come
          poter migliorare l'adeguatezza stessa nell'area.
        </p>
      </div>

      <div class="response-section" v-for="section in result.sections" :key="section.number" :class="{'semaphore-green': section.semaphore === 'green', 'semaphore-yellow': section.semaphore === 'yellow', 'semaphore-red': section.semaphore === 'red', 'semaphore-grey': section.semaphore === 'grey'}">
        <div class="response-section-header">
          <h3>
            {{ section.number | stepTitle }}

            <span v-if="section.score !== undefined" class="response-score">[{{ section.score | score }}]</span>
          </h3>

          <img v-if="section.semaphore === 'green'" class="face" :src="require('@/assets/images/response/happy-face-p.png')" />
          <img v-if="section.semaphore === 'green'" class="bg" :src="require('@/assets/images/response/bg-green.png')" />
          <img v-if="section.semaphore === 'yellow'" class="face" :src="require('@/assets/images/response/neutral-face-p.png')" />
          <img v-if="section.semaphore === 'yellow'" class="bg" :src="require('@/assets/images/response/bg-yellow.png')" />
          <img v-if="section.semaphore === 'red'" class="face" :src="require('@/assets/images/response/sad-face-p.png')" />
          <img v-if="section.semaphore === 'red'" class="bg" :src="require('@/assets/images/response/bg-red.png')" />
          <img v-if="section.semaphore === 'grey'" class="bg" :src="require('@/assets/images/response/bg-grey.png')" />
        </div>

        <div v-if="section.advices.length === 0">Non sono presenti avvisi per questa sezione.</div>
        <div class="advice" v-for="(advice, i) in section.advices" :key="i" v-html="advice"></div>
      </div>
    </div>
  </div>
  <span v-else />
</template>

<script>

import { mapGetters } from 'vuex';
import surveyMixin from '@/mixins/surveyMixin.js';
import goToStepMixin from '@/mixins/goToStepMixin.js';
import stepTitleFilter from '@/filters/stepTitleFilter.js';

import Intro from '@/views/components/Intro.vue';
import Step1Form from '@/views/components/Steps/Step1Form.vue';
import Step2Form from '@/views/components/Steps/Step2Form.vue';
import Step3Form from '@/views/components/Steps/Step3Form.vue';
import Step4Form from '@/views/components/Steps/Step4Form.vue';
import Step5Form from '@/views/components/Steps/Step5Form.vue';

export default {
  mixins: [surveyMixin, goToStepMixin],
  components: {
    Intro,
    Step1Form,
    Step2Form,
    Step3Form,
    Step4Form,
    Step5Form,
  },
  filters: {
    stepTitle: stepTitleFilter,
    score (value) {
      if (!value && value !== 0) {
        return '';
      }

      return parseInt((parseFloat(value || 0) * 100) || 0) + '%';
    },
  },
  computed: {
    ...mapGetters('survey', {
      companyName: 'companyName',
      referenceYear: 'referenceYear',
      hasResult: 'hasResult',
      result: 'getResult',
    }),
  },
  methods: {
    downloadBilancioDiGenere () {
      this.$swal.fire({
        title: 'Elaborazione del Bilancio di Genere in corso...',
        showConfirmButton: false,
        showCancelButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false,
        willOpen: () => {
          this.$swal.showLoading();

          this.$api.getBilancioDiGenere({
            headers: this.$store.state.survey.headers,
            preferences: this.$store.state.survey.preferences,
            data: this.$store.state.survey.data,
          })
            .then(res => {
              const blob = res.data;
              const reader = new FileReader();
              reader.readAsDataURL(blob);

              reader.onload = e => {
                const a = document.createElement('a');
                a.download = `${this.companyName}-${this.referenceYear}.zip`;
                a.href = e.target.result;
                a.innerHTML = 'Download';
                a.classList.add('btn');
                a.classList.add('btn-success');
                a.setAttribute('id', 'download-bdg');
                const wrap = document.createElement('div');
                wrap.appendChild(a);

                this.$swal.fire({
                  title: 'Bilancio di Genere elaborato',
                  showConfirmButton: false,
                  showCancelButton: false,
                  allowOutsideClick: false,
                  allowEscapeKey: false,
                  footer: wrap.innerHTML,
                  didRender: () => {
                    document.getElementById('download-bdg').addEventListener('click', () => {
                      this.$swal.close();
                    });
                  },
                });
              };
            })
          ;
        },
      });
    },
  },
  mounted () {
    if (!this.hasResult) {
      this.goToStep(1);
    }
  },
  destroyed () {
    this.$store.commit('survey/resetResult');
  },
};

</script>

<style lang="scss">
</style>
