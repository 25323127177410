var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"question"},[_c('span',{staticClass:"question-title"},[_vm._v("Indicare il numero e il sesso dei vostri ASPP e preposti/e")]),_c('QuestionNumberRow',{attrs:{"question":"ASPP","name":"education_nb_rspp","strong":false,"edit":_vm.edit}}),_c('QuestionNumberRow',{attrs:{"question":"Preposti/e","name":"education_nb_safety_responsibles","strong":false,"edit":_vm.edit}})],1),_c('QuestionRadio',{attrs:{"edit":_vm.edit,"name":"education_rls_training_gender_weight","options":[
    { value: 1, label: 'Sì, solo per quanto riguarda gli aspetti legati alla gravidanza' },
    { value: 2, label: 'Sì, anche per altri aspetti inerenti le differenze di sesso e genere' },
    { value: 0, label: 'No' } ]},scopedSlots:_vm._u([{key:"question",fn:function(){return [_vm._v("* Nell'ambito delle attività informative/formative previste per i/le vostri/e rspp, rls o preposti/e sono stati trattati argomenti inerenti le differenze di "),_c('span',{attrs:{"data-explained-by-term":""},on:{"click":function($event){return _vm.showTerm('sesso')}}},[_vm._v("sesso")]),_vm._v(" e "),_c('span',{attrs:{"data-explained-by-term":""},on:{"click":function($event){return _vm.showTerm('genere')}}},[_vm._v("genere")]),_vm._v("?")]},proxy:true}])}),_c('QuestionRadio',{attrs:{"edit":_vm.edit,"name":"education_worker_training_gender_weight","options":[
    { value: 1, label: 'Sì, solo per quanto riguarda gli aspetti legati alla gravidanza' },
    { value: 2, label: 'Sì, anche per altri aspetti inerenti le differenze di sesso e genere' },
    { value: 0, label: 'No' } ]},scopedSlots:_vm._u([{key:"question",fn:function(){return [_vm._v("* Nell'ambito delle attività informative/formative previste per i lavoratori e le lavoratrici avete inserito argomenti inerenti le differenze di "),_c('span',{attrs:{"data-explained-by-term":""},on:{"click":function($event){return _vm.showTerm('sesso')}}},[_vm._v("sesso")]),_vm._v(" e "),_c('span',{attrs:{"data-explained-by-term":""},on:{"click":function($event){return _vm.showTerm('genere')}}},[_vm._v("genere")]),_vm._v("?")]},proxy:true}])}),_c('QuestionRadio',{attrs:{"edit":_vm.edit,"name":"education_is_rls_election_date_agreed","options":[
    { value: 1, label: 'Sì' },
    { value: 2, label: 'No, perché gli/le RLS sono di nomina RSU, o sono esclusivamente RLS territoriali' },
    { value: 0, label: 'No' } ]},scopedSlots:_vm._u([{key:"question",fn:function(){return [_vm._v("* La programmazione della riunione per l'elezione degli/lle RLS è stata concordata con i lavoratori e le lavoratrici per facilitare una equa partecipazione di tutti e tutte?")]},proxy:true}])}),_c('QuestionRadio',{attrs:{"edit":_vm.edit,"name":"education_did_rls_asked_for_safety_and_gender","options":[
    { value: 1, label: 'Sì e le tematiche sono state affrontate nei corsi di formazione' },
    { value: 3, label: 'Sì e sono in programma corsi di formazione inerenti tali tematiche' },
    { value: 2, label: 'Sì ma il DL non intende affrontare tali tematiche nei corsi di formazione' },
    { value: 0, label: 'No' } ]},scopedSlots:_vm._u([{key:"question",fn:function(){return [_vm._v("* Gli/le RLS hanno richiesto di affrontare tematiche di sicurezza e "),_c('span',{attrs:{"data-explained-by-term":""},on:{"click":function($event){return _vm.showTerm('genere')}}},[_vm._v("genere")]),_vm._v("?")]},proxy:true}])}),(_vm.isUniversita)?_c('QuestionRadio',{attrs:{"edit":_vm.edit,"name":"gender_related_formation_technical","options":[
    { value: 1, label: 'Sì' },
    { value: 0, label: 'No' },
    { value: 2, label: 'Altro', extra: 'gender_related_studies_other_what' } ]},scopedSlots:_vm._u([{key:"question",fn:function(){return [_vm._v("* Sono previste attività formative/informative sulle tematiche di genere per il personale tecnico amministrativo?")]},proxy:true}],null,false,2394179021)}):_vm._e(),(_vm.isUniversita)?_c('QuestionCheckbox',{attrs:{"edit":_vm.edit,"name":"gender_related_formation_technical_what","options":[
    { value: 1, label: 'Brochure' },
    { value: 2, label: 'Video' },
    { value: 'other', label: 'Altro', extra: 'what' } ]},scopedSlots:_vm._u([{key:"question",fn:function(){return [_vm._v("* E’ prevista un'attività di divulgazione sulle tematiche di genere e la condivisione alla genitorialità per il personale tecnico amministrativo?")]},proxy:true}],null,false,2284554897)}):_vm._e(),(_vm.$modals.terms)?_c('InfoModal',{attrs:{"modal-class":"term-modal","title":_vm.term},on:{"close":_vm.closeTerm}},[_c('div',{domProps:{"innerHTML":_vm._s(_vm.termText)}})]):_vm._e(),(_vm.$modals.laws)?_c('InfoModal',{attrs:{"modal-class":"law-modal","title":_vm.law},on:{"close":_vm.closeLaw}},[_c('h2',[_vm._v(_vm._s(_vm.lawTitle))]),_c('div',{domProps:{"innerHTML":_vm._s(_vm.lawText)}})]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }